import { style } from '@do/walrus';
import _get from 'lodash/get';
import { useRouter } from 'next/router';
import React from 'react';
import { HitsProvided } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';
import styled from 'styled-components';
import {
  AlgoliaApp,
  AlgoliaSearchResult
} from '../../interfaces/AlgoliaSearch';
import Image from '../Image';
import Highlight from './Highlight';

export const SearchResults = styled.div`
  width: 100%;
  background: white;
  border-color: ${style.colors.primary.base};
  border-style: solid;
  border-width: 0 1px 1px 1px;
  font-size: ${style.vars.fontSize.base};
  color: ${style.colors.grey.darkest};
  padding: ${style.vars.space['2']} 0;
  max-height: 260px;
  overflow: auto;
  cursor: pointer;
`;

const SearchResultRow = styled.div`
  padding: ${style.vars.space['2']} ${style.vars.space['3']};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;

  &.is-active {
    background: ${style.colors.grey.lightest};
  }
`;

const IconColumn = styled.div`
  width: 24px;
  margin-right: ${style.vars.space['2']};
`;

const DetailColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const SearchResultHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SearchResultName = styled(Highlight)`
  font-weight: 700 !important;
  padding: 0;
`;

const SearchResultCategory = styled(Highlight)`
  color: ${style.colors.grey.dark};
  font-size: ${style.vars.fontSize.small};
  font-weight: normal;
`;

const SearchResultDescription = styled(Highlight)`
  font-size: ${style.vars.fontSize.small};
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SearchNoResult = styled.div`
  padding: ${style.vars.space['2']} ${style.vars.space['3']};
  color: ${style.colors.grey.dark};
`;

interface Props extends HitsProvided<AlgoliaSearchResult> {
  inputValue: string;
  indexContextValue: string;
  getItemProps: any;
  highlightedIndex?: number;
}

const SearchResultsList: React.FC<Props> = ({
  hits,
  getItemProps,
  highlightedIndex,
  inputValue
}: Props) => {
  const router = useRouter();
  const pathSafeName = _get(router, ['query', 'safeName']);
  const filteredHits = hits
    // If user is on an app's detail page, exclude current app from results
    .filter((hit) => {
      const safeName = _get(hit, ['marketplace', 'safeName', 0]);
      return safeName !== pathSafeName;
    });

  return (
    <SearchResults>
      {filteredHits.length > 0 &&
        filteredHits.map((hit, index) => {
          const app: AlgoliaApp = _get(hit, 'marketplace');
          const name = _get(app, 'name');
          const icon = _get(app, ['customData', 'icon']);

          return (
            <SearchResultRow
              {...getItemProps({
                item: hit,
                index,
                key: hit.objectID,
                className: highlightedIndex === index ? 'is-active' : ''
              })}
            >
              <IconColumn>
                {icon && <Image src={icon} alt={name} width={24} height={24} />}
              </IconColumn>
              <DetailColumn>
                <SearchResultHeader>
                  <SearchResultName hit={hit} attribute="marketplace.name" />
                  <SearchResultCategory
                    hit={hit}
                    attribute="marketplace.customData.tags[0]"
                  />
                </SearchResultHeader>
                <SearchResultDescription hit={hit} attribute="description" />
              </DetailColumn>
            </SearchResultRow>
          );
        })}
      {inputValue && filteredHits.length === 0 && (
        <SearchNoResult>
          No results found containing '{inputValue}'.
        </SearchNoResult>
      )}
    </SearchResults>
  );
};

export default connectHits(SearchResultsList) as React.ComponentType<Props>;
